const builderStaticInputs = {
	details: [
		{ name: 'pageTitle', label: 'Page Heading', type: 'text' },
		{ name: 'tagline', label: 'Page Tagline', type: 'text' },
		{ name: 'heroImage', label: 'Hero Image', type: 'image' },
	],
	launch: [
		{ name: 'subdomain', label: 'Subdomain for full-page hosting', type: 'text' },
	],
}

export default builderStaticInputs