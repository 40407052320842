import React from 'react';
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

function PricingPage () {
	return ( 
		<Container>
			<Typography variant="h2">Pricing</Typography>
		</Container>
	 );
}

export default PricingPage;