import React from 'react';
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

function CaseStudiesPage () {
	return ( 
		<Container fixed>
			<Typography variant="h2">Case Studies</Typography>
		</Container>
	 );
}

export default CaseStudiesPage;