import React from 'react';
import Container from '@mui/material/Container'
import { Typography } from '@mui/material';

function UseCasesPage () {
	return ( 
		<Container>
			<Typography variant="h2">Use Cases</Typography>
		</Container>
	 );
}

export default UseCasesPage;